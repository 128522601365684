<script lang="ts">
    import { Toaster } from 'svelte-french-toast';
    import CookieConsent from './Components/CookieConsent.svelte';
</script>

<main>
    <Toaster />
    <CookieConsent />
    <slot />
</main> 

